import kiwiLogo from "./images/kiwicon.png";
import twitterLogo from "./images/twitterlogo.png";
import { Link } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={kiwiLogo} className="App-logo" alt="logo" />
        <p>You have entered the Kiwi zone. This is fine.</p>
        <a href="https://twitter.com/IgnKiwi">
          <img
            src={twitterLogo}
            className="smedia-logo"
            alt="twitter logo"
          ></img>
        </a>
      </header>
    </div>
  );
}

export default App;
